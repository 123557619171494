.carousel-root {
  width: 100%;
}
.control-arrow {
  border-radius: 0 20px 20px 0;
  &:hover {
    background-color: #28b4af !important;
    &::before {
      border-left: 18px solid #ffffff !important;
      border-right: none !important;
    }
  }
  &::before {
    border-left: 18px solid #28b4af !important;
    border-right: none !important;
  }
}

.control-prev {
  border-radius: 20px 0 0 20px;
  &:hover {
    background-color: #28b4af !important;
    &::before {
      border-right: 18px solid #ffffff !important;
      border-left: none !important;
    }
  }
  &::before {
    border-left: none !important;
    border-right: 18px solid #28b4af !important;
  }
}

.thumbs-wrapper {
  .control-arrow {
    border-radius: 0 20px 20px 0;
    height: 40px;
    right: -5px !important;
    top: 40% !important ;

    &:hover {
      background-color: #28b4af !important;
      &::before {
        border-left: 8px solid #ffffff !important;
        border-right: none !important;
      }
    }
    &::before {
      border-left: 8px solid #28b4af !important;
      border-right: none !important;
    }
  }
  .control-prev {
    border-radius: 20px 0 0 20px;
    height: 40px;
    left: -5px !important;
    top: 40% !important ;

    &:hover {
      background-color: #28b4af !important;
      &::before {
        border-right: 8px solid #ffffff !important;
        border-left: none !important;
      }
    }
    &::before {
      border-left: none !important;
      border-right: 8px solid #28b4af !important;
    }
  }
}

.carousel-status {
  right: 35px !important;
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.034) !important;
  border-radius: 20px;
  img,
  video {
    border-radius: 16px;
    &:focus {
      outline: none;
    }
  }
  video {
    max-height: 426px;
    // width: 51.949%;
  }
}
body {
  .carousel-root {
    .carousel-slider {
      // position: unset;
    }
    .carousel {
      // position: unset;
      .thumb {
        transition: all 0.2s ease;
        border: none;
        vertical-align: middle;
        width: 13% !important;
        min-width: 120px;
        // max-height: 150px;
        border-radius: 16px;
        &:hover {
          cursor: pointer;
          border: 2px solid #28b4af;
          border-radius: 16px;
        }
        img,
        video {
          border-radius: 15px;
          aspect-ratio: 1/1;
        }
        div {
          display: flex;

          img,
          video {
            border-radius: 15px;
          }
        }
      }

      .control-dots {
        bottom: 0;
        left: 0;
        .dot {
          // background: #28b4af;
        }
      }
    }
  }

  .vids-carousel {
    display: flex;
    justify-content: center;

    div {
      transition: all 0.2s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed transparent;
      border-radius: 15px;

      margin-right: 20px;
      &:hover {
        cursor: pointer;
        border: 2px solid #28b4af;
        border-radius: 16px;
      }
      video {
        width: 150.953px;
        height: 100.469px;
        object-fit: cover;
        border-radius: 15px;
        &:hover {
          transform: scale(0.97);
        }
      }
    }
  }
}

.carousel-root .carousel .thumb.selected {
  border: 2px solid #28b4af;
  border-radius: 16px;
}

.legend {
  background-color: #28b4af !important;
  display: none;
}

@media (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:active {
    background: #28b4af;
    border: 0;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track:hover,
  ::-webkit-scrollbar-track:active {
    background: #fff;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}

::selection {
  background: #28b4af;
  color: white;
}
