@import url(http://fonts.googleapis.com/css?family=Open+Sans:700);
@import url(http://netdna.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css);

@import "./assets/_tailwind.css";

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav {
  position: relative;
  width: 700px;
  height: 80px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav:hover .link {
  width: 5%;
}
.nav .link {
  position: relative;
  float: left;
  width: 33.33%;
  height: 100%;
  color: #aaa;
  border-right: 1px solid #ddd;
  box-shadow: -5px -5px 10px rgba(238, 238, 238, 0.699);
  transition: 0.5s width;
  overflow: hidden;
  cursor: pointer;
}
.nav .link:first-child {
  box-shadow: none;
}
.nav .link:last-child {
  border-right: 0;
}
.nav .link:hover {
  width: 100%;
  color: #555;
}
.nav .link:hover .small {
  color: #28b4af;
}

.nav .link .small {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  line-height: 78px;
  text-align: center;
  font-family: fontawesome;
  font-size: 24px;
}
.nav .link .full {
  position: absolute;
  top: 22px;
  left: 100px;
  text-transform: uppercase;
}
.nav .link .full .f1,
.nav .link .full .f2 {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
}

.nav .link .full .f2 {
  margin-top: 8px;
  font-size: 12px;
}
.nav .link .prev {
  position: absolute;
  top: 0;
  left: 7px;
  font-family: fontawesome;
  font-size: 12px;
  line-height: 78px;
  transition: 0.5s opacity;
  opacity: 0;
}
.nav .link:hover .prev {
  opacity: 0;
}
.nav:hover .prev {
  opacity: 1;
}

@media (max-width: 700px) {
  section {
    width: 100%;
  }
  .nav {
    width: 100%;
    height: auto;
    flex-direction: column;
    padding: 0;
  }
  .nav:hover .link {
    width: 100%;
  }
  .nav .link {
    width: 100%;
    height: 58px;
    border-right: unset;
  }
  .nav:hover .prev {
    opacity: 0;
  }
  .nav .link .full .f1 {
  font-size: 13px;
}
.nav .link .full .f2 {
  display: none;
}
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal {
  width: clamp(50%, 700px, 90%);
  height: min(50%, 300px);
  margin: auto;
  border-radius: 12px;
}
