@mixin rings($duration, $delay) {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 8px solid rgba(0, 0, 0, 0.082);
  border-radius: 9999px;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: -99;
}

#action-button-container {
  position: relative;
  z-index: 10;
  isolation: isolate;
  .action-button {
    position: relative;
    background: inherit;
    isolation: isolate;
    overflow: hidden;
    min-width: 174px;
    max-height: 61.23px;
    transition: all 0.6s;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @media (max-width: 767px) {
      min-width: 30px;
      width: 95px !important;
      height: 40px !important;
    }
    @media (max-width: 1024px) {
      min-width: 30px;
      width: 135px;
      height: 55px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
      filter: blur(10px);
      background: inherit;
      overflow: hidden;
      z-index: -1;
    }
  }
  &:not(:hover) {
    &::before {
      @include rings(3s, 0s);
    }
    &::after {
      @include rings(3s, 0.5s);
    }
  }
}

#side-bar {
  #action-button-container {
    .action-button {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0 0 2000px rgb(255, 255, 255);
        filter: unset;
        background: inherit;
        overflow: hidden;
        z-index: -1;
      }
    }
  }
}
@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.98, 0.98, 1);
    border: 8px solid rgba(0, 0, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
    border: 8px solid rgba(0, 0, 0, 0.13);
  }
}
